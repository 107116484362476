import { useEffect, useMemo, useState } from "react";
import { StoryObject } from "../types/Story";

export const ObjectViewer = ({
    inventory,
}: {
    inventory: StoryObject[];
}) => {
    const [selectedObject, setSelectedObject] = useState<StoryObject | null>(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const selectedImage = useMemo(() => selectedObject?.images[selectedImageIndex], [selectedObject, selectedImageIndex]);
    useEffect(() => {
        if (!selectedObject && inventory.length > 0) {
            setSelectedObject(inventory[0]);
        }
    }, [inventory]);
    return (
        <div className="h-100 d-flex flex-column pt-3">
            {Array.isArray(inventory) && inventory.length > 0 ?
                <>
                    {selectedImage ? (
                        <div style={{
                            backgroundImage: `url(${selectedImage})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            flex: 1,
                        }} className="FadeInElement"></div>
                    ) : null}
                    <div className="d-flex mb-4">
                        <div
                            onClick={() => setSelectedImageIndex((selectedImageIndex - 1 + (selectedObject?.images || []).length) % (selectedObject?.images || []).length)}
                            className="flex-grow-1 d-flex justify-content-center align-items-center"
                            style={{
                                opacity: (selectedImageIndex === 0) ? 0.25 : 1,
                                cursor: (selectedImageIndex === 0) ? 'not-allowed' : 'pointer',
                            }}
                        >Précédent</div>
                        <div
                            onClick={() => window.open(selectedImage || '', '_blank')}
                            className="flex-grow-1 d-flex justify-content-center align-items-center"
                            style={{
                                cursor: 'pointer',
                            }}
                        >Agrandir</div>
                        <div
                            onClick={() => setSelectedImageIndex((selectedImageIndex + 1) % (selectedObject?.images || []).length)}
                            className="flex-grow-1 d-flex justify-content-center align-items-center"
                            style={{
                                opacity: (selectedImageIndex === (selectedObject?.images.length || 0) - 1) ? 0.25 : 1,
                                cursor: (selectedImageIndex === (selectedObject?.images.length || 0) - 1) ? 'not-allowed' : 'pointer',
                            }}
                        >Suivant</div>
                    </div>
                    <div className="text-start pb-2">
                        <div className="d-block text-uppercase fw-light text-center">Inventaire</div>
                        <div className="border rounded px-2 py-1" style={{backgroundColor: '#333'}}>
                            {inventory.map((obj) => (
                                <div
                                    className="d-inline-block mx-1 FadeInElement"
                                    key={obj.id}
                                    onClick={() => {
                                        setSelectedObject(obj)
                                        setSelectedImageIndex(0)
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        opacity: selectedObject === obj ? 1 : 0.4,
                                    }}
                                >
                                    <div style={{
                                        backgroundImage: `url(${obj.images[0]})`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        width: 96,
                                        height: 96,
                                    }}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            : null}
        </div>
    );
}

export default ObjectViewer;