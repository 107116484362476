export function getQueryParams(url: string): Record<string, string> {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const queryParams: {
        [key: string]: string;
    } = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });

    return queryParams;
}