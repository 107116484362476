import { useMemo } from 'react';
import './App.scss';
import BoardGameLayout from './components/BoardGameLayout';
import Stories from './assets/story';
import { getQueryParams } from './helpers/Browser';

function App() {
    const currentStory = useMemo(() => {
        const url = window.location.href;
        const queryParams = getQueryParams(url);
        const storyId = queryParams['storyId'];
        const story = Stories[storyId || '_NULL_'];
        if (story) {
            return story;
        }
        return null;
    }, []);
    if (!currentStory) {
        return (
            <div className="d-flex justify-content-center align-items-center h-100">
                <h1>Story not found</h1>
            </div>
        );
    }
    return (<BoardGameLayout story={currentStory} />);
}

export default App;
