import { useEffect, useMemo, useRef, useState } from "react";
import { Story, StoryObject, StoryScenario } from "../types/Story";
import { Button, Form } from "react-bootstrap";
import Markdown from "react-markdown";

const SanitizeString = (str: string) =>
        str
            .toLocaleLowerCase()
            .replaceAll(/[^a-zA-Z0-9]/g, '')
;
const WAIT_TIMER = 1000;

const EnigmSegment = ({
    story,
    scenario,
    scenarioIndex,
    currentIndex,
    onSolve,
    onNewObject,
}: {
    story: Story;
    scenario: StoryScenario;
    scenarioIndex: number;
    currentIndex: number;
    onSolve?: () => void;
    onNewObject?: (objs: StoryObject[]) => void;
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isSolved, setIsSolved] = useState(false);

    const handleNewObject = () => {
        if (onNewObject && scenario.onSuccessObject) {
            const newObjetcts = scenario.onSuccessObject.map((objId) => story.objects.find((obj) => obj.id === objId));
            onNewObject(newObjetcts as StoryObject[]);
        }
    };
    // const [inputValue, setInputValue] = useState('');
    const handleSolve = () => {
        const userInput = inputRef.current?.value || '';
        const str = SanitizeString(userInput);
        const solution: string[] = scenario.solution.map(str => SanitizeString(str));
        if (!solution.includes(str)) {
            inputRef.current?.focus();
            // @ts-ignore
            inputRef.current.value = '';
            return;
        }
        setIsSolved(true);
        // setInputValue(userInput);
        if (onSolve) {
            handleNewObject();
            setTimeout(() => {
                onSolve();
            }, (scenario.waitBefore || WAIT_TIMER) + 500);
        }
    };

    useEffect(() => {
        if (isSolved) return;
        if (currentIndex < scenarioIndex) return;
        if (scenario.type === 'text') {
            setIsSolved(true);
            setTimeout(() => {
                handleNewObject();
                if (onSolve) {
                    onSolve();
                }
            }, (scenario.waitBefore || WAIT_TIMER) + 500);
        }
    }, [currentIndex, isSolved])
    if (currentIndex < scenarioIndex) {
        return null;
    }

    const readOnlyElement = (
        <div className={'FadeInElementMove mt-3'}>
            {scenario.onSuccessSpeech?.map((speech, index) => (
                <div
                    key={index}
                    className="fs-6 fw-light mb-0 ps-3"
                >
                    <Markdown
                        components={{
                            p: ({node, ...props}) => <div {...props} className="d-block mb-0" />,
                            strong: ({node, ...props}) => <span {...props} className="fw-bold" />,
                        }}
                    >{speech}</Markdown>
                </div>
            ))}
        </div>
    );

    if (scenario.type === 'text') {
        return (
            <div className="d-flex flex-column px-0">
                {readOnlyElement}
            </div>
        );
    }

    return (
        <div className="d-flex flex-column px-0">
            <div className="">
                {isSolved ? (readOnlyElement) : (
                    <div className="px-3 FadeInElement">
                        <div className="fs-4 fw-normal mb-2 mt-3">{scenario.question}</div>
                        <Form.Control
                            type="text"
                            placeholder="Réponse"
                            className="mb-4 mt-2"
                            ref={inputRef}
                        />
                        <Button
                            size="sm"
                            onClick={handleSolve}
                        >Deviner</Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export const EnigmaStep = ({
    scenarios,
    story,
    onNewObject,
    currentScenarioIndex,
    setCurrentScenarioIndex,
}: {
    scenarios: StoryScenario[];
    story: Story;
    currentScenarioIndex: number;
    setCurrentScenarioIndex: (index: number) => void;
    onNewObject?: (objs: StoryObject[]) => void;
}) => {
    return (
        <div className="d-flex flex-column px-2 py-2">
            {scenarios.map((scenario, index) => (
                <EnigmSegment
                    key={scenario.id}
                    story={story}
                    scenario={scenario}
                    scenarioIndex={index}
                    currentIndex={currentScenarioIndex}
                    onSolve={() => setCurrentScenarioIndex(index + 1)}
                    onNewObject={onNewObject}
                />
            ))}
        </div>
    );
};

export default EnigmaStep;