import { Col, Container, Row } from 'react-bootstrap';
import { Story, StoryObject } from '../types/Story';
import Markdown from 'react-markdown';
import { useEffect, useMemo, useState } from 'react';
import ObjectViewer from './ObjectViewer';
import EnigmaStep from './EnigmaStep';

export const BoardGameLayout = ({
    story,
}: {
    story: Story;
}) => {
    const [inventory, setInventory] = useState<StoryObject[]>([]);
    const [currentScenarioIndex, setCurrentScenarioIndex] = useState(0);
    useEffect(() => {
        if (story.introduction.objectsOnStart) {
            setInventory(story.objects.filter((obj) => story.introduction.objectsOnStart?.includes(obj.id)));
        }
    }, []);
    return (
        <div className="d-flex justify-content-center align-items-center flex-column h-100 py-4">
            <Container className='flex-grow-1 d-flex flex-column pb-5'>
                <Row>
                    <Col>
                    <div className='display-3 mb-3 text-center fw-light' style={{opacity: 0.4}}>{story.introduction.title}</div>
                    <div className='fs-4 mb-4 text-center fw-lighter'>{story.introduction.description}</div>
                    </Col>
                </Row>
                <Row className="flex-grow-1 border rounded bg-app-dark shadow-sm">
                    <Col xs={12} md={6}>
                        <ObjectViewer
                            inventory={inventory}
                        />
                    </Col>
                    <Col xs={12} md={6} className='border-start'>
                        <EnigmaStep
                            story={story}
                            scenarios={story.scenario}
                            currentScenarioIndex={currentScenarioIndex}
                            setCurrentScenarioIndex={setCurrentScenarioIndex}
                            onNewObject={(objs: StoryObject[]) => {
                                const newInventory = [...inventory, ...objs];
                                setInventory(newInventory);
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BoardGameLayout;
