import { Story } from '../../types/Story';
import ClaraFosterStory001 from './clara_foster_001.json';

export const Stories = [ClaraFosterStory001 as Story];

export const StoryList = Stories.reduce((acc, story) => {
    acc[story.id] = story;
    return acc;
}, {} as Record<string, Story>);

export default StoryList;